import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css';

function GalleryEdit() {
  const [gallery, setGallery] = useState({});
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios.get(`${serverUrl}/gallery/${id}`, { withCredentials: true })
      .then(response => {
        console.log(response.data)
        const galleryData = response.data;
        setGallery(galleryData);
        if (galleryData) {
          setName(galleryData.name || "");
          setDescription(galleryData.description || "");
        } else {
          console.error(`No gallery data received for id: ${id}`);
        }
      })
      .catch(error => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [id, serverUrl]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const onImageSelectionChange = (event) => {
    if (event.target.checked) {
      setSelectedImages([...selectedImages, event.target.value]);
    } else {
      setSelectedImages(selectedImages.filter(imageId => imageId !== event.target.value));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  
    const formData = {
      name: name,
      description: description
    };
  
    axios.put(`${serverUrl}/gallery/edit/${id}`, formData)
      .then(res => {
        console.log(res);
        navigate(`/gallery/${id}`);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onDeleteImages = () => {
    axios.post(`${serverUrl}/gallery/edit/${id}/delete`, { deleteImages: selectedImages })
      .then(res => {
        console.log(res);
        setSelectedImages([]); // Clear the selection
        // Fetch the gallery data again to update the images
        axios.get(`${serverUrl}/gallery/${id}`)
          .then(response => {
            setGallery(response.data);
          })
          .catch(error => {
            console.error('Error fetching data: ', error);
          });
      })
      .catch(error => {
        console.error(error);
      });
  };  

  // Render the form only if name and description have been set
  if (name !== undefined && description !== undefined) {
    return (
      <div>
        <h2>Edit Gallery</h2>
        <form onSubmit={onSubmit}>
          <div>
            <label htmlFor="name">Name:</label>
            <input id="name" type="text" value={name} onChange={onNameChange} />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea id="description" value={description} onChange={onDescriptionChange} />
          </div>
          <button type="submit">Save Changes</button>
        </form>
        <div className="image-grid">
          {gallery.images && gallery.images.map(image => (
            <div key={image.id} className="image-item">
              <input type="checkbox" id={`image${image.id}`} value={image.id} onChange={onImageSelectionChange} />
              <label htmlFor={`image${image.id}`}>
                <img src={image.url} className="thumbnail" alt={image.name} />
              </label>
              <p>{image.description}</p>
            </div>
          ))}
        </div>
        <button onClick={onDeleteImages}>Delete Selected Images</button><br />
        <button onClick={() => {
          if(window.confirm('Are you sure you want to delete this gallery?')) {
            // Make a DELETE request to the server
            axios.delete(`${serverUrl}/gallery/${id}`)
              .then(res => {
                console.log(res);
                // If the deletion was successful, navigate back to the gallery list
                navigate('/gallery');
              })
              .catch(error => {
                console.error(error);
              });
          }
        }}>Delete Gallery</button>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

export default GalleryEdit;
