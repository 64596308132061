import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import './styles.css';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Upload() {
  const [galleries, setGalleries] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false); // New state for loading screen
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios.get(`${serverUrl}/gallery`, { withCredentials: true })
      .then(response => {
        console.log('Response Data: ', response.data);
        setGalleries(response.data.galleries);
        setSelectedGallery(response.data.galleries[0]?.id || ""); // set selectedGallery here
      })
      .catch(error => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [serverUrl]);

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onGalleryChange = (event) => {
    setSelectedGallery(event.target.value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("image", file);
    });
    formData.append("gallery", selectedGallery);
    formData.append("description", description);
  
    axios.post(`${serverUrl}/upload`, formData, {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    })
    .then(res => {
      console.log(res);
      setLoading(false);
      navigate(`/gallery/${selectedGallery}`);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    });
  };

  return (
    <div>
      <h2>Upload a new image</h2>
      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="gallery">Select a gallery:</label>
          <select id="gallery" onChange={onGalleryChange}>
            {galleries.map(gallery => (
              <option key={gallery.id} value={gallery.id}>{gallery.name}</option>
            ))}
          </select>
        </div>
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag and drop some files here, or click to select files</p>
          }
        </div>
        <div>
          {files.map((file, index) => (
            <p key={index}>{file.name}</p>
          ))}
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <input id="description" type="text" onChange={onDescriptionChange} />
        </div>
        <button type="submit">Upload</button>
        <p>Upload progress: {uploadProgress}%</p>
      </form>
      {/* Loading screen */}
      {loading && (
        <div className="loading-screen">
          <BeatLoader color={"#123abc"} loading={true} css={override} size={15} />
        </div>
      )}
    </div>
  );
}

export default Upload;
