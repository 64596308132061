import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import './styles.css';
import SearchBar from './SearchBar';

function Header() {
    const { user } = useUser(); 
    const siteName = process.env.REACT_APP_SITE_NAME;

    return (
        <header className="header">
            <h1 className="header-title">{siteName}</h1>
            <nav className="header-nav">
                <ul className="header-nav-list">
                    {user ? (
                        <>
                            <li>Welcome, {user.username}!</li>
                            <li><Link to="/logout">Logout</Link></li>
                            <li><Link to="/gallery">Galleries</Link></li>
                            <li><Link to="/upload">Upload</Link></li>
                            <li><Link to="/gallery/new">Create Gallery</Link></li>
                            <li><SearchBar /></li>
                        </>
                    ) : (
                        <>
                            <li>No user logged in</li>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/gallery">Galleries</Link></li>
                            <li><SearchBar /></li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
