import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';

function Logout() {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios.post(`${serverUrl}/logout`, {}, { withCredentials: true })
      .then(() => {
        setUser(null); // clear the user data in the context
        navigate('/login');
      })
      .catch((error) => {
        console.error(`Error during logout: ${error}`);
      });
  }, [navigate, setUser, serverUrl]);

  // Render nothing, as this component only performs the logout operation
  return null;
}

export default Logout;
