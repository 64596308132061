import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function GalleryForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const onSubmit = (event) => {
    event.preventDefault();
  
    axios.post(`${serverUrl}/gallery/new`, { name, description }, { withCredentials: true })
      .then((response) => {
        console.log('Full response from server: ', response);
        console.log('Response data from server: ', response.data);
        // Access the id of the new gallery directly from response.data.id
        navigate(`/gallery/${response.data.id}`);
      })
      .catch((error) => {
        console.error(`Error creating new gallery: ${error}`);
      });
    };

  return (
    <div>
      <h2>Create a new gallery</h2>
      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="gallery-name">Gallery name:</label>
          <input id="gallery-name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label htmlFor="gallery-description">Description:</label>
          <textarea id="gallery-description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
        <button type="submit">Create</button>
      </form>
    </div>
  );
}

export default GalleryForm;
