import React, { useState, useEffect } from 'react';  // Import useEffect
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  // This useEffect hook logs the searchResults whenever it changes
  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);

  const handleSearch = async (e) => {
    e.preventDefault();
  
    // Send a GET request to the /search endpoint with the search term
    const response = await axios.get(`${serverUrl}/search`, {
      params: {
        term: searchTerm
      }
    });
  
    const { rows } = response.data; // Use rows instead of galleries and images
  
    setSearchResults(rows); // Use rows instead of spreading galleries and images
    navigate(`/search?term=${encodeURIComponent(searchTerm)}`);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <input type="text" value={searchTerm} onChange={handleInputChange} placeholder="Search..." />
        <button type="submit">Search</button>
      </form>
      {/* Render the search results */}
      <ul>
        {searchResults.map((result, index) => (
          <li key={index}>
            {/* Display the result details */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchBar;
