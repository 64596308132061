import React from 'react';
import placeholderImage from './placeholder.png';

function GalleryGrid({ gallery }) {
  let images = [...gallery.images];
  const randomImages = [];

  // If there are no images, just return a single placeholder image
  if (images.length === 0) {
    return (
      <img
        src={placeholderImage}
        alt="Placeholder"
        className="thumbnail"
      />
    );
  }

  for (let i = 0; i < 4; i++) {
    if (images.length === 0) {
      images = [...gallery.images]; // Reset the images array if all images have been used
    }
    const index = Math.floor(Math.random() * images.length);
    const image = images[index];
    randomImages.push(image);
    images.splice(index, 1); // Remove the chosen image from the temporary array
  }

  return (
    <div className="gallery-grid">
      {randomImages.map((image, index) => (
        <img
          key={index}
          src={image.url}
          alt={image.name}
          className="gallery-grid-thumbnail"
        />
      ))}
    </div>
  );
}

export default GalleryGrid;
