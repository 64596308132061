import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../UserContext';

function ProtectedRoute({ element: Element }) {
  const { user, isLoading } = useUser();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>; // or some loading indicator
  }

  return user ? React.cloneElement(Element, { location }) : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
