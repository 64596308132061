import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './styles.css'; 
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

function GalleryDetail() {
  const { id } = useParams();
  const [gallery, setGallery] = useState(null);
  const [images, setImages] = useState(null);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(15);
  const { user, isLoading } = useUser();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  // Fetch gallery details
  useEffect(() => {
    axios.get(`${serverUrl}/gallery/${id}`, { withCredentials: true })
      .then(response => {
        setGallery(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, [id, serverUrl]);

  // Fetch paginated images
  useEffect(() => {
    axios.get(`${serverUrl}/gallery/${id}/images`, { 
      params: { limit: limit, offset: (pageNumber - 1) * limit }, // Send the limit and offset in the request
      withCredentials: true 
    })
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, [id, serverUrl, pageNumber, limit]); // Add pageNumber and limit to the dependency array

  if (!gallery || !images) {
    return null;
  }

  return (
    <div>
      <div className="subHeader">
        <h2>{gallery.name}</h2>

        {/* Number of images per page */}
        <label>
          Images per page: 
          <select 
            value={limit} 
            onChange={event => setLimit(Number(event.target.value))}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>
      {user && !isLoading && (
        <button onClick={() => navigate(`/gallery/edit/${id}`)}>Edit Gallery</button>
      )}      
      <div className="image-grid">
        {images.rows && images.rows.map(image => ( // Change gallery.images to gallery.rows
          <div key={image.id} className="image-item">
            <img 
              src={image.url} 
              alt={image.description} 
              className="thumbnail" 
              onClick={() => setLightboxImage(image)} // Set the clicked image as the lightbox image
            />
            <p>{image.originalName.replace(/_/g, '\u200B_')}</p>  {/* Add zero-width spaces before underscores */}
            <p>{image.description}</p>
          </div>
        ))}
      </div>

      {/* Lightbox */}
      {lightboxImage && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.9)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            padding: '1em',
          }}
          onClick={() => setLightboxImage(null)}
        >
          <p style={{ fontSize: '1.2em', marginBottom: '1em' }}>Click anywhere to close</p>
          <img src={lightboxImage.url} alt={lightboxImage.description} style={{maxHeight: '60%', maxWidth: '80%'}} />
          <p style={{ marginTop: '1em', fontSize: '1.2em' }}>{lightboxImage.originalName.replace(/_/g, '\u200B_')}</p>
          <p>{lightboxImage.description}</p>
        </div>
      )}

      {/* Page controls */}
      <div className="pageControls">
        <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>Previous</button>
        <div>Page {pageNumber} of {Math.ceil(images.count / limit)}</div>
        <button onClick={() => setPageNumber(pageNumber + 1)} disabled={images.rows.length < limit}>Next</button>
      </div>
    </div>
  );
}

export default GalleryDetail;
