import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    console.log('Server URL in UserContext:', serverUrl);
    axios.get(serverUrl + '/user', { withCredentials: true })
      .then(response => {
        setUser(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(`Error fetching user: ${error}`);
        setIsLoading(false);
      });
  }, [serverUrl]);

  return (
    <UserContext.Provider value={{ user, isLoading, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
