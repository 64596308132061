import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Login from './components/Login';
import GalleryList from './components/GalleryList';
import Gallery from './components/GalleryDetail';
// import Image from './components/Image';
import Upload from './components/Upload';
import GalleryForm from './components/GalleryForm';
import GalleryEdit from './components/GalleryEdit'
import { UserProvider } from './UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './components/Logout'
import SearchResults from './components/SearchResults';

function App() {
  return (
    <UserProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<GalleryList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/gallery" element={<GalleryList />} />
          <Route path="/gallery/:id" element={<Gallery />} />
          <Route path="/gallery/edit/:id/*" element={<ProtectedRoute element={<GalleryEdit />} />} />
          <Route path="/gallery/new/*" element={<ProtectedRoute element={<GalleryForm />} />} />
          <Route path="/upload/*" element={<ProtectedRoute element={<Upload />} />} />
          <Route path="/logout/*" element={<ProtectedRoute element={<Logout />} />} />
          <Route path="/search" element={<SearchResults />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;