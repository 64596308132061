import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import './styles.css';
import GalleryGrid from './GalleryGrid';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchResults() {
  let query = useQuery();
  const [results, setResults] = useState(null);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let searchTerm = query.get('term');
  const [lightboxImage, setLightboxImage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    axios.get(`${serverUrl}/search`, {
      params: { term: searchTerm, limit: limit, offset: (pageNumber - 1) * limit },
      withCredentials: true
    })
    .then(response => {
      setResults(response.data);
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
    });
  }, [serverUrl, searchTerm, pageNumber, limit]);

  if (!results) {
    return null;
  }

  return (
    <div>
      <div className="subHeader">
        <h2>Search Results</h2>

        {/* Number of images per page */}
        <label>
          Images per page: 
          <select 
            value={limit} 
            onChange={event => setLimit(Number(event.target.value))}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>
      <div className="image-grid">
        {results.rows.map(result => (
          <div key={result.id} className="image-item">
            {result.url ? (
            // This is an image
            <>
              <img 
                src={result.url} 
                alt={result.description} 
                className="thumbnail" 
                onClick={() => setLightboxImage(result)}
              />
              <p>{result.originalName.replace(/_/g, '\u200B_')}</p>
              <p>{result.description}</p>
            </>
            ) : (
            // This is a gallery
            <>
              {result.images && result.images.length > 0 ? (
                <Link to={`/gallery/${result.id}`}>
                  <GalleryGrid gallery={result} />
                </Link>
              ) : (
                <Link to={`/gallery/${result.id}`}>
                  <GalleryGrid gallery={result} />
                </Link>
              )}
              <p>{result.name}</p>
              <p>{result.description}</p>
            </>
            )}
          </div>
        ))}
      </div>
      {/* Lightbox */}
      {lightboxImage && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.9)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            padding: '1em',
          }}
          onClick={() => setLightboxImage(null)}
        >
          <p style={{ fontSize: '1.2em', marginBottom: '1em' }}>Click anywhere to close</p>
          <img src={lightboxImage.url} alt={lightboxImage.description} style={{maxHeight: '60%', maxWidth: '80%'}} />
          <p style={{ marginTop: '1em', fontSize: '1.2em' }}>{lightboxImage.originalName.replace(/_/g, '\u200B_')}</p>
          <p>{lightboxImage.description}</p>
        </div>
      )}
      <div className="pageControls">
        <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>Previous</button>
        <div>Page {pageNumber} of {Math.ceil((results.countImages + results.countGalleries) / limit)}</div>
        <button onClick={() => setPageNumber(pageNumber + 1)} disabled={results.rows.length < limit}>Next</button>
      </div>
    </div>
  );
}

export default SearchResults;
