import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GalleryThumbnail from './GalleryThumbnail';
import './styles.css'; 

function GalleryList() {
  const [galleries, setGalleries] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios.get(serverUrl + '/gallery', { 
      params: { page: page, limit: limit }, // Pass limit as a parameter to the server
      withCredentials: true 
    })
      .then(response => {
        setGalleries(response.data.galleries);
        setTotal(response.data.pagination.totalGalleries);
      })
      .catch(error => {
        console.error(`Error fetching data: ${error}`);
      });
  }, [serverUrl, page, limit]); // Include limit in the dependency array  

  const nextPage = () => {
    if (galleries.length === limit) {
      setPage(page + 1);
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  return (
    <div>
      <div className="subHeader">
        <h2>Galleries</h2>
        {/* Number of images per page */}
        <label>
          Images per page: 
          <select 
            value={limit} 
            onChange={event => setLimit(Number(event.target.value))}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>
      <div className="image-grid">
        {galleries.map(gallery => (
          <GalleryThumbnail key={gallery.id} gallery={gallery} />
        ))}
      </div>
      {/* Page controls */}
      <div className="pageControls">
        <button onClick={prevPage} disabled={page === 1}>Previous</button>
        <div>Page {page} of {Math.ceil(total / limit)}</div>
        <button onClick={nextPage} disabled={galleries.length < limit}>Next</button>
      </div>
    </div>
  );
}

export default GalleryList;
