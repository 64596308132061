import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css'; 
// import placeholderImage from './placeholder.png';
import GalleryGrid from './GalleryGrid';

function GalleryThumbnail({ gallery }) {
//  const randomImage = gallery.images[Math.floor(Math.random() * gallery.images.length)];

  return (
    <div className="gallery-thumbnail">
      <Link to={`/gallery/${gallery.id}`}>
      <GalleryGrid gallery={gallery} />
      </Link>
        <p>{gallery.description}</p>
      <Link to={`/gallery/${gallery.id}`}>
        <h2>{gallery.name}</h2>
      </Link>
    </div>
  );
}

export default GalleryThumbnail;
